import { defineStore } from "pinia";
import { AdAccount, AdAccountResponse } from "~/types/AdAccount";
import { Provider } from "~/types/shared";

export const AD_ACCOUNT_STORE = "ad-account-store";
const BATCH_SIZE = 100;

export const useAdAccountStore = defineStore({
  id: AD_ACCOUNT_STORE,
  state: () => {
    return {
      adAccounts: [],
    } as {
      adAccounts: Array<AdAccount>;
    };
  },
  actions: {
    async listAdAccounts() {
      const { data, error } = await useDatAdsApiFetch<{
        data: { adAccounts: Array<AdAccountResponse> };
      }>("adaccount");
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        const { getMappedAdAccounts } = useAdAccounts();
        this.adAccounts = getMappedAdAccounts(data.value.data.adAccounts);
      }
      return this.adAccounts;
    },

    async assignAdAccountsToClients(
      adAccounts: Array<{
        adAccountId: number;
        clientId: number;
      }>,
    ) {
      // Do an optimistic update i.e. update internal state first even, if API operation is not successful
      // This helps flickering of UI during API call
      for (const adAccount of adAccounts) {
        const index = this.adAccounts.findIndex(
          (a) => a.id === adAccount.adAccountId,
        );
        if (index !== -1) {
          this.adAccounts[index] = {
            ...this.adAccounts[index],
            deleted: false,
            clientIds: [
              ...this.adAccounts[index].clientIds,
              adAccount.clientId,
            ],
          };
        }
      }
      // Update in batches
      for (let i = 0; i < adAccounts.length; i += BATCH_SIZE) {
        const { error } = await useDatAdsApiFetch("adaccount/assign", {
          method: "POST",
          body: adAccounts.slice(i, i + BATCH_SIZE),
        });
        if (error.value) {
          const errorMessage = useErrorHandler(error.value);
          return errorMessage;
        }
      }
      await this.listAdAccounts();
      await useClientStore().listClients();
      return null;
    },

    async removeAdAccountsFromClients(
      adAccounts: Array<{
        adAccountId: number;
        clientId: number;
      }>,
    ) {
      // Do an optimistic update i.e. update internal state first even, if API operation is not successful
      // This helps flickering of UI during API call
      for (const adAccount of adAccounts) {
        const index = this.adAccounts.findIndex(
          (a) => a.id === adAccount.adAccountId,
        );
        if (index !== -1) {
          this.adAccounts[index] = {
            ...this.adAccounts[index],
            deleted: false,
            clientIds: this.adAccounts[index].clientIds.filter(
              (clientId) => clientId !== adAccount.clientId,
            ),
          };
        }
      }
      // Update in batches
      for (let i = 0; i < adAccounts.length; i += BATCH_SIZE) {
        const { error } = await useDatAdsApiFetch("adaccount/remove", {
          method: "POST",
          body: adAccounts.slice(i, i + BATCH_SIZE),
        });
        if (error.value) {
          const errorMessage = useErrorHandler(error.value);
          return errorMessage;
        }
      }
      await this.listAdAccounts();
      await useClientStore().listClients();
      return null;
    },

    async removeAdAccountsFromAllClients(adAccountIds: number[]) {
      // Update in batches
      for (let i = 0; i < adAccountIds.length; i += BATCH_SIZE) {
        const { error } = await useDatAdsApiFetch("adaccount/remove/all", {
          method: "POST",
          body: adAccountIds.slice(i, i + BATCH_SIZE).map((adAccountId) => ({
            adAccountId,
          })),
        });
        if (error.value) {
          const errorMessage = useErrorHandler(error.value);
          return errorMessage;
        }
      }
      this.adAccounts = this.adAccounts.filter(
        (adAccount) => !adAccountIds.includes(adAccount.id),
      );
      return null;
    },
  },
  getters: {
    getAdAccountById:
      (state) =>
      (adAccountId: number): AdAccount | null => {
        const acc = state.adAccounts.find(
          (adAccount) => adAccount.id === adAccountId,
        );
        return acc || null;
      },

    notDeletedAdAccounts: (state) =>
      state.adAccounts
        .filter((adAccount) => !adAccount.deleted)
        .sort((a, b) => a.id - b.id),

    deletedAdAccounts: (state) =>
      state.adAccounts
        .filter((adAccount) => adAccount.deleted)
        .sort((a, b) => a.id - b.id),

    customConversionNames:
      (state) =>
      (dto: {
        clientId: number;
        providers: Provider[];
      }): Record<string, string> => {
        return state.adAccounts
          .filter(
            (adAccount) =>
              adAccount.clientIds.includes(dto.clientId) &&
              dto.providers.includes(adAccount.provider),
          )
          .map((adAccount) => adAccount.customConversionNames)
          .reduce((acc, customConversionNames) => {
            return { ...acc, ...customConversionNames };
          }, {});
      },
  },
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAdAccountStore, import.meta.hot));
}
