import { AdAccount, AdAccountResponse } from "~/types/AdAccount";
import { Provider } from "~/types/shared";

export const useAdAccounts = () => {
  const getMappedAdAccounts = (
    adAccounts: Array<AdAccountResponse>,
  ): Array<AdAccount> => {
    const { getProviderLogo, providerToDisplayName } = useConnection();

    return adAccounts
      .map<AdAccount>((adAccount) => {
        return {
          ...adAccount,
          displayName: adAccount.name,
          logo: getProviderLogo(adAccount.provider),
          providerDisplayName: providerToDisplayName(adAccount.provider),
          deleted:
            typeof adAccount.deleted === "boolean" ? adAccount.deleted : false,
        };
      })
      .sort((a, b) => a.id - b.id);
  };

  const getCustomConversionToProviderMap = (): Record<string, Provider> => {
    const map: Record<string, Provider> = {};
    const { adAccounts } = useAdAccountStore();
    for (const adAccount of adAccounts) {
      for (const conversionId of Object.keys(adAccount.customConversionNames)) {
        map[conversionId] = adAccount.provider;
      }
    }
    return map;
  };

  return {
    getMappedAdAccounts,
    getCustomConversionToProviderMap,
  };
};
