import {
  createAvatar,
  getProviderPrefix,
  removeProviderPrefix,
} from "~/shared/utils";
import { Client, ClientResponse } from "~/types/Client";
import {
  providerToFallbackPrimaryMetric,
  providerToFallbackSecondaryMetrics,
} from "~/types/Metrics";
import { Provider, Sort, AdProvider, AttributionWindow } from "~/types/shared";

export const useClients = () => {
  const sortByIsAllClientsAndId = (a: Client, b: Client) => {
    if (a.isAllClients) {
      return -1;
    }
    if (b.isAllClients) {
      return 1;
    }
    return a.id - b.id;
  };

  const getMappedClients = (
    clients: Array<ClientResponse>,
    sortFn: (a: Client, b: Client) => number = sortByIsAllClientsAndId,
  ): Array<Client> => {
    const { appUserMe } = useAppUserStore();
    if (!appUserMe) {
      return [];
    }
    const mappedClients = clients
      .filter((client) => client.workspaceId === appUserMe.activeWorkspace)
      .map((client) => {
        const displayImage =
          typeof client.logo === "string" && client.logo.length > 0
            ? client.logo
            : createAvatar(client.name, "initials");

        return {
          ...client,
          displayName: client.name,
          displayWebsite:
            typeof client.website === "string" && client.website.length > 0
              ? client.website
              : "---",
          displayImage,
        };
      });

    if (sortFn != null) {
      mappedClients.sort(sortFn);
    }

    return mappedClients;
  };

  const getSorts = (client: Client): Record<Provider, Sort> => {
    return AdProvider.reduce(
      (acc, provider) => {
        acc[provider] = getProviderSort(client, provider);
        return acc;
      },
      {} as Record<Provider, Sort>,
    );
  };

  const getProviderSort = (client: Client, provider: Provider) => {
    const sortMaybe = client.sorts.find(
      (sortPrefixed) => getProviderPrefix(sortPrefixed) === provider,
    );
    if (sortMaybe) {
      const sort = removeProviderPrefix(sortMaybe);
      return sort as Sort;
    }
    return Sort.DESC;
  };

  const getPrimaryMetrics = (client: Client): Record<Provider, string> => {
    return AdProvider.reduce(
      (acc, provider) => {
        acc[provider] = getProviderPrimaryMetric(client, provider);
        return acc;
      },
      {} as Record<Provider, string>,
    );
  };

  const getProviderPrimaryMetric = (client: Client, provider: Provider) => {
    const primaryMetricMaybe = client.primaryMetrics.find(
      (primaryMetricPrefixed) =>
        getProviderPrefix(primaryMetricPrefixed) === provider,
    );
    if (primaryMetricMaybe) {
      return removeProviderPrefix(primaryMetricMaybe);
    }
    return providerToFallbackPrimaryMetric[provider];
  };

  const getSecondaryMetrics = (client: Client): Record<Provider, string[]> => {
    return AdProvider.reduce(
      (acc, provider) => {
        acc[provider] = getProviderSecondaryMetrics(client, provider);
        return acc;
      },
      {} as Record<Provider, string[]>,
    );
  };

  const getProviderSecondaryMetrics = (client: Client, provider: Provider) => {
    const metricsMaybe = client.secondaryMetrics.filter(
      (secondaryMetricPrefixed) =>
        getProviderPrefix(secondaryMetricPrefixed) === provider,
    );
    if (metricsMaybe.length > 0) {
      return metricsMaybe.map((metric) => removeProviderPrefix(metric));
    }
    return providerToFallbackSecondaryMetrics[provider];
  };

  const getAttributionWindows = (
    client: Client,
  ): Record<Provider, AttributionWindow> => {
    return AdProvider.reduce(
      (acc, provider) => {
        acc[provider] = getProviderAttributionWindow(client, provider);
        return acc;
      },
      {} as Record<Provider, AttributionWindow>,
    );
  };

  const getProviderAttributionWindow = (client: Client, provider: Provider) => {
    const attributionWindowMaybe = client.attributionSettings.find(
      (attributionWindowPrefixed) =>
        getProviderPrefix(attributionWindowPrefixed) === provider,
    );
    if (attributionWindowMaybe) {
      return removeProviderPrefix(attributionWindowMaybe) as AttributionWindow;
    }
    return AttributionWindow.DEFAULT;
  };

  return {
    getMappedClients,
    getSorts,
    getPrimaryMetrics,
    getSecondaryMetrics,
    getAttributionWindows,
    getProviderSort,
    getProviderPrimaryMetric,
    getProviderSecondaryMetrics,
    getProviderAttributionWindow,
  };
};
